import {boAxios} from "@/network";

export const quiz_store = {
    state: {
        quizzes: {}
    },
    mutations: {
        ADD_QUIZZES(state, quizzes) {
            quizzes.forEach(quiz => {
                if (state.quizzes[quiz.level] === undefined) {
                    state.quizzes[quiz.level] = []
                }
                state.quizzes[quiz.level].push(quiz)
            })
        }
    },
    actions: {
        async fetch_quizzes({state, dispatch}, {level}) {
            if (state.quizzes[level]) return state.quizzes[level];
            return await dispatch('fetch_quizzes_from_server', {level})
        },
        async fetch_quizzes_from_server({commit}, {level}) {
            //fetch
            let url = `/quiz/list/`
            if (level) {
                url += level
            }
            try {
                const {data, status} = await boAxios(url);
                if (status !== 200) {
                    return false
                }
                commit('ADD_QUIZZES', data.items)
                return data.items;
            }
            catch (e) {
                console.error(e);
                return false
            }
        }
    },
    getters: {
        getQuizzes: state => (level) => {
            return state.quizzes[level];
        }
    }
}
