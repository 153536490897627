export const bulk_delete_store = {
    state: {
        usernames: []
    },
    mutations: {
        ADD_USERNAMES_FOR_BULK_DELETE(state, usernames) {
            state.usernames.push(...usernames)
        },
        SET_USERNAMES_FOR_BULK_DELETE(state, usernames) {
            state.usernames = usernames;
        }
    },
}
