import axios from "axios";
import {CMS_API_PREFIX} from "@/constants";
import {throttleAdapterEnhancer} from "axios-extensions";
import {EventBus, NOTIFY_EVENT} from "@/eventbus";

const throttleConfig = {
    threshold: 2*1000 // 2 seconds
}

const boAxios = axios.create({
    baseURL: process.env.CMS_API_PREFIX || CMS_API_PREFIX,
    adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig)
});

boAxios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    EventBus.$emit(NOTIFY_EVENT, {
        type: 'error',
        message: error
    })
    // Do something with response error
    return Promise.reject(error);
});

export {
    boAxios
}
