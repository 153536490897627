import Vue from 'vue';
export const EventBus = new Vue({
    methods: {
        notify(message, type='info') {
            this.$emit(NOTIFY_EVENT, {message, type})
        }
    }
});

/*** EVENTS **/
export const USER_UPDATED_EVENT = 'USER_UPDATED_EVENT'
export const USER_DELETED_EVENT = 'USER_DELETED_EVENT'
export const CLASS_UPDATED_EVENT = 'CLASS_UPDATED_EVENT'
export const CLASS_DELETED_EVENT = 'CLASS_DELETED_EVENT'
export const SCHOOL_CREATED_EVENT = 'SCHOOL_CREATED_EVENT'
export const SCHOOL_UPDATED_EVENT = 'SCHOOL_UPDATED_EVENT'
export const CUSTOM_SCHOOL_CONVERTED_EVENT = 'CUSTOM_SCHOOL_CONVERTED_EVENT'
export const PREMIUM_SCHOOL_CREATED_EVENT = 'PREMIUM_SCHOOL_CREATED_EVENT'
export const PREMIUM_SCHOOL_UPDATED_EVENT = 'PREMIUM_SCHOOL_UPDATED_EVENT'
export const NOTIFY_EVENT = 'notify'
