import Vue from 'vue'
import Vuex from 'vuex'
import {quiz_store} from "@/store/modules/quiz";
import {bulk_delete_store} from "@/store/modules/bulk_delete_store";
import {bulk_subscription_store} from "@/store/modules/bulk_subscription_store";
import {bulk_actions_store} from "@/store/modules/bulk_actions";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    groups: [],
    session: null,
    ad_sizes: [
      '[336, 280]',
      '[300, 250]',
      '[250, 250]',
      '[320, 100]',
      '[320, 50]',
      'fluid',
    ],
    loading: false
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_GROUPS(state, groups) {
      state.groups = groups
    },
    SET_SESSION(state, session) {
      state.session = session
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setUser({commit}, payload) {
      commit('SET_USER', payload)
    },
    setGroups({commit}, payload) {
      commit('SET_GROUPS', payload)
    },
    setSession({commit}, payload) {
      commit('SET_SESSION', payload)
    },
    setGlobalLoading({commit}, payload) {
      commit('SET_LOADING', payload)
    }
  }

  ,
  modules: {
    quiz: quiz_store,
    bulk_delete: bulk_delete_store,
    bulk_subscriptions: bulk_subscription_store,
    bulk_actions: bulk_actions_store
  }
})
