import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VuePapaParse from 'vue-papa-parse'
import VueAxios from "vue-axios";
import {boAxios} from "@/network";
import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import JsonEditor from 'vue-json-edit'

Amplify.configure(awsconfig);

Vue.config.productionTip = false
Vue.use(VuePapaParse);
Vue.use(VueAxios, boAxios)
Vue.use(JsonEditor)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
