export const bulk_subscription_store = {
    state: {
        usernames: [],
        school_name: '',
        subscriptionObj: {
          active: true
        }
    },
    mutations: {
        ADD_USERNAMES_FOR_BULK_SUB(state, usernames) {
            state.usernames.push(...usernames)
        },
        SET_USERNAMES_FOR_BULK_SUB(state, usernames) {
            state.usernames = usernames;
        },
        SET_SCHOOL_NAME(state, school_name) {
            state.school_name = school_name;
        },
        SET_SUB_OBJ(state, subObj) {
            state.subscriptionObj = subObj;
        }
    },
}
