<template>
  <transition-group tag="div" name="fade">
    <!-- If using vue-router -->
    <v-alert class="alert-z-index" v-for="alert in notifications" :key="alert.id" dismissible close-text="dismiss" :type="alert.type">{{alert.message}}</v-alert>
  </transition-group>
</template>

<script>

import _ from "lodash";

const DEFAULT_ALERT_SETTINGS = {
  type: 'info',
  timeout: 5
}

export default {
  name: "NotificationsWidget",
  data() {
    return {
      notifications: []
    }
  },
  methods: {
    notify(message, type, timeout) {
      let alert = _.defaults({message, type, timeout}, DEFAULT_ALERT_SETTINGS)
      alert.id = _.uniqueId('alert');
      if (alert.timeout > 0) {
        setTimeout(function(id) {
          this.notifications = _.remove(this.notifications, item => item.id === id);
        }.bind(this), alert.timeout*1000, [alert.id ])
      }
      this.notifications.push(alert);
    }
  }
}
</script>

<style scoped>
  .alert-z-index {
    width: 80%;
    z-index: 9999;
  }
</style>
